import React from 'react';
import PropTypes from 'prop-types';
import './PercentageBar.css';

const PercentageBar = ({ percentage }) => {
  return (
    <div className="percentage-bar">
      <div className="percentage-bar-fill" style={{ width: `${percentage}%` }}>
        {/* {percentage > 0 ? `${percentage}%` : ''} */}
      </div>
    </div>
  );
};

PercentageBar.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default PercentageBar;